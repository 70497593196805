import { NextSeo } from 'next-seo'
import useTranslation from '@/hooks/useTranslation'
import { DashboardPage } from '@/pages-ui/index'
import { LoggedInAreaTemplate } from '@/templates/index'

export default function Home() {
  const { t } = useTranslation('seo')

  return (
    <LoggedInAreaTemplate>
      <DashboardPage />
      <NextSeo title={t?.titles?.dashboard} />
    </LoggedInAreaTemplate>
  )
}
